import React from "react";
import loader from "../../assets/images/loader.svg";

class CircularProgress extends React.Component {
   render() {
      return (
         <div className={`loader ${this.props.className}`}>
            <img src={loader} alt="loader" />
         </div>
      );
   }
}

export default CircularProgress;