import moment from 'moment';

export function momentLocale(currentLocale) {
   if(!currentLocale) return;
   const locale = currentLocale.toLowerCase();
   if(locale === moment.locale()) return;
   const loadLocales = (locale) => {
      try {
         return require(`moment/locale/${locale}`);
      } catch (error) {
         return "en-in";
      }
   };
   const locales = loadLocales(locale);
   moment.defineLocale(locale, locales);
}
