const languageData = [
  {
    languageId: 'pt-BR',
    locale: 'pt-BR',
    name: 'Português',
    icon: 'br'
  },
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  }
];
export default languageData;
