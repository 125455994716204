import React from "react";
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";
import asyncComponent from "./util/asyncComponent"
import configureStore, { history } from './appRedux/store';

// provide initial state if any
const store = configureStore();

const NextApp = () => {
   return (
      <Provider store={store}>
         <ConnectedRouter history={history}>
            <Switch>
               <Route path="/" component={asyncComponent(() => import('./containers/App/index') )} />
            </Switch>
         </ConnectedRouter>
      </Provider>
   );
}


export default NextApp;
