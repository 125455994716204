import React, { Component } from "react";
import Nprogress from "nprogress";
import ReactPlaceholder from "react-placeholder";
import CircularProgress from "components/CircularProgress";
import "react-placeholder/lib/reactPlaceholder.css";
// import "nprogress/nprogress.css";

export default function (importComponent) {

   Nprogress.configure({ showSpinner: false });
   Nprogress.start();

   class AsyncFunc extends Component {

      constructor(props) {
         super(props);
         this.state = {
            component: null
         };
      }

      componentWillUnmount() {
         this.mounted = false;
      }

      async componentDidMount() {
         this.mounted = true;
         const { default: Component } = await importComponent();
         Nprogress.done();
         if (this.mounted) {
            this.setState({
               component: <Component {...this.props} />
            });
         }
      }

      render() {
         const Component = this.props.loading ? <CircularProgress /> : this.state.component || <CircularProgress />;
         return (
            <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
               {Component}
            </ReactPlaceholder>
         );
      }
   }

   return AsyncFunc;
}
